import { Router, RouterLocation } from '@vaadin/router';
import _ from 'lodash';
import { EventService } from './events-service';

const routerObserver = EventService.getEventBus();
let isRouterReadyPromise: Promise<RouterLocation | boolean>;
let globalRouter: Router;

export const isRouterReady = () => {
  isRouterReadyPromise ||= globalRouter?.ready;

  isRouterReadyPromise ||= new Promise(resolve => {
    routerObserver.on('ready', () => resolve(true));
  });

  return isRouterReadyPromise;
};

export const setRoutes = async (placement: HTMLElement, routes) => {
  const baseUrl = new URL(document.baseURI).pathname;

  globalRouter ??= new Router(placement, { baseUrl });
  globalRouter.setRoutes(routes);

  await globalRouter.ready;
  routerObserver.fireEvent('ready');

  return globalRouter;
};

export const getRouteParam = async (param: string) => {
  return (await isRouterReady()) && globalRouter.location.params[param];
};

export const hasRouteParam = async (param: string) => {
  return (await isRouterReady()) && _.has(globalRouter.location.params, param);
}

export const getRouteSearchParam = async (param: string) => {
  return (await isRouterReady()) && new URLSearchParams(globalRouter.location.search).get(param);
};

export const getGlobalRouteSearchParam = (param: string) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(param);
};

export const getLocalStorageSearchParm = (param: string) => {
  return localStorage.getItem(param);
};
